import React, { useEffect } from "react";

import { HomePage } from "pages/home/home.page";

import "./app.module.scss";

export function App() {
  useEffect(() => {
    if (window && document) {
      const script = document.createElement("script");
      const body = document.getElementsByTagName("body")[0];
      script.src = process.env.PUBLIC_URL + "/optin.min.js";
      body.appendChild(script);
    }
  }, []);

  return (
    <div>
      <HomePage />
    </div>
  );
}
