import React, { useState } from "react";

import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";

import styles from "./newsletter.module.scss";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

export const Newsletter: React.FC<Props> = ({ children, className }) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [isSubmitted, setSubmitted] = useState<boolean>(false);

  const handleSubmit = () => {
    setSubmitting(true);
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.content}>
        {children}
        <h3 className={styles.title}>Enter Your Email To Join Teeka For FREE:</h3>
        {isSubmitted && (
          <div>
            <p className={styles.submissionText}>Thank you! Your submission has been received!</p>
          </div>
        )}
        {!isSubmitted && (
          <form
            action="https://api.visionaryprofit.com/optin"
            method="post"
            className="optin"
            onSubmit={handleSubmit}
            style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}
          >
            <div className={styles.inputWrapper}>
              <ArrowLeft className={styles.arrowLeft} />
              <label>
                <input name="email" type="email" placeholder="E-mail address" className={styles.input} required />
              </label>
              <ArrowRight className={styles.arrowRight} />
            </div>
            <input type="hidden" name="utms" />
            <input type="hidden" name="info" />
            <input
              type="submit"
              value={submitting ? "Please wait" : `Yes, Send Me Teeka's Special Report`}
              className={styles.button}
            />
          </form>
        )}
      </div>
    </div>
  );
};
