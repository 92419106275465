import React from "react";

import { ReactComponent as Nbc } from "assets/icons/nbc.svg";
import { ReactComponent as Fox } from "assets/icons/fox.svg";
import { ReactComponent as Abc } from "assets/icons/abc.svg";
import { ReactComponent as Quote } from "assets/icons/quote.svg";

import styles from "./as-seen-on.module.scss";

export const AsSeenOn: React.FC = () => {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>As seen on:</h3>

      <div className={styles.row}>
        <Nbc />
        <Fox />
        <Abc />
      </div>

      <div className={styles.grid}>
        <div className={styles.gridItem}>
          <div>
            <Quote />
            <span className={styles.gridItemText}>“I am a crypto millionaire because of you.”</span>
          </div>
        </div>
        <div className={styles.gridItem}>
          <div>
            <Quote />
            <span className={styles.gridItemText}>
              “I have fortunately been a follower of your for many years and had the great fortune of earning a little
              more than 1 million dollars from your recommendations.”
            </span>
          </div>
        </div>
        <div className={styles.gridItem}>
          <div>
            <Quote />
            <span className={styles.gridItemText}>
              “We bought several alt coins and did well, and for that reason we want to thank you.”
            </span>
          </div>
        </div>
        <div className={styles.gridItem}>
          <div>
            <Quote />
            <span className={styles.gridItemText}>
              “I had been a 5 year subscriber there, and have done VERY WELL … following your many crypto
              recommendations.”
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
