import React from "react";

import bannerL from "assets/images/banner-l.webp";
import bannerM from "assets/images/banner-m.webp";
import bannerS from "assets/images/banner-s.webp";
import bitcoin from "assets/images/bitcoin.webp";
import teeka from "assets/images/teeka.webp";

import styles from "./banner.module.scss";

export const Banner: React.FC = () => {
  return (
    <div className={styles.container}>
      <img
        src={bannerL}
        alt="banner"
        className={styles.bannerImage}
        srcSet={`${bannerS} 800w, ${bannerM} 1200w, ${bannerL} 1440w`}
      />
      <div className={styles.content}>
        <div className={styles.header}>
          <h4>
            In 2015, He Picked Bitcoin At $428... The World&apos;s First <u>Trillion-Dollar Crypto</u>...
          </h4>
          <p>
            Then He Recommended 27 Other Coins That Jumped Up <strong>By 1,000% or more</strong>
          </p>
        </div>

        <div className={styles.box}>
          <div>
            <img src={bitcoin} className={styles.bitcoin} alt="Bitcoin" />
          </div>
          <div>
            <p className={styles.isBackText}>
              Now, Teeka <span className={styles.hiddenMobileText}>Tiwari</span> Is{" "}
              <span style={{ whiteSpace: "nowrap" }}>
                <strong>BACK</strong> With:
              </span>
            </p>
            <h2 className={styles.boxTitle}>Crypto&apos;s Next Big Shocking Prediction</h2>
          </div>
        </div>

        <div className={styles.grid}>
          <div>
            <h2 className={styles.numberOneTitle}>The #1 Wealth-Building Opportunity of 2024...</h2>
            <p className={styles.numberOneDesc}>
              Your Once In A Lifetime Chance To Make Life-Altering{" "}
              <strong>
                <u>
                  <span className={styles.hiddenMobileText}>Six and Even</span> Seven-Figure Windfalls.
                </u>
              </strong>{" "}
            </p>
          </div>

          <img src={teeka} alt="Teeka Tiwari" className={styles.teekaImage} />
        </div>
      </div>
    </div>
  );
};
