import React from "react";

import styles from "./footer.module.scss";

export const Footer: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p>
          Copyright {new Date().getFullYear()} <a href="https://visionaryprofit.com/">VisionaryProfit.com</a> All Rights
          Reserved
        </p>

        <p>
          Disclaimer. While the examples above are real 3 the results may not be typical
          <br />
          All investing involves risk and you should never invest more than you&apos;re prepared to lose
          <br />
          <br />
          Miami 99 E Flagler St, Miami, 1 FL 33131 USA
        </p>

        <div className={styles.linksWrapper}>
          <a href="https://visionaryprofit.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>{" "}
          |
          <a href="https://visionaryprofit.com/terms-conditions/" target="_blank" rel="noopener noreferrer">
            Terms & Conditions
          </a>{" "}
          |
          <a href="https://visionaryprofit.com/contact-us/" target="_blank" rel="noopener noreferrer">
            Contact US
          </a>
        </div>
      </div>
    </div>
  );
};
