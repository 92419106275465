import React from "react";
import ReactDOM from "react-dom/client";

import { App } from "./app";

import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("mm-root") as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
